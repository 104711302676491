.main-content {
  min-height: 100%;

  @include media(">small") {
    justify-content: center;
    align-items: center;
  }
  @include media("<medium") {
    flex-direction: column;
  }

  .column-inner {
    @include media("<medium") {
      margin: 0 0 auto;
    }
  }

  .logo-wrapper {
    width: 100%;
  }

  .logo {
    max-height: 26px;
    max-width: 100%;
    flex-shrink: 0;
    margin: 26px 0 16px;

    &.subdomain {
      max-height: 64px;
    }
  }

  h1 {
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 24px;
    font-weight: normal;

    line-height: 1.12;
    letter-spacing: normal;
    color: rgba(18, 32, 32, 0.9);
    margin: 0;
    margin-bottom: 4px;

    @include media(">small") {
      margin-top: 28px;
      margin-bottom: 11px;

      font-size: 34px;
    }
  }

  h2 {
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 14px;
    font-weight: normal;

    line-height: 1.25;
    letter-spacing: normal;
    color: rgba(18, 32, 32, 0.6);
    margin: 0;
    margin-bottom: 26px;

    @include media(">small") {
      font-size: 16px;
      color: rgba(18, 32, 32, 0.3);
    }
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media(">small") {
      display: block;
      margin: 10%;
    }

    &.has-iso-icon:after {
      content: "";
      background-repeat: no-repeat;
      display: block;
      background-size: contain;
      background-position: bottom;
      background-image: url("../../../static/img/footer/dqs-iso.svg");
      width: 70px;
      margin-bottom: 5px;

      @include media(">small") {
        display: none;
      }
    }

    nav {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: flex-start;

      @include media(">small") {
        align-items: center;
        flex-direction: row;
      }

      &.has-iso-icon:after {
        content: "";
        background-repeat: no-repeat;
        display: none;
        background-size: contain;
        background-position: bottom;
        background-image: url("../../../static/img/footer/dqs-iso.svg");
        width: 81px;
        min-width: 81px;
        height: 67px;
        padding-left: 20px;

        @include media(">small") {
          display: block;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "CeraPro";
        font-feature-settings: "ss01" 1;
        font-size: 10px;
        font-weight: normal;

        line-height: 1;
        letter-spacing: 0.57px;
        text-align: center;
        color: $colors-01-primary-02-dark-night-black;
        text-decoration: none;
        color: rgba(18, 32, 32, 0.9);
        white-space: nowrap;
        margin-bottom: 5px;

        &:last-child:after {
          height: 0;
          margin-right: 30px;
        }

        @include media(">small") {
          font-size: 14px;
          margin-left: 20px;
          margin-bottom: 0;
        }
      }
    }

    .copyright {
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 10px;
      font-weight: normal;

      line-height: 1;
      letter-spacing: normal;
      color: $colors-01-primary-04-system-grey-d-3;
      margin-bottom: 8px;

      @include media(">small") {
        display: none;
      }
    }
  }
}
