.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  @include media(">medium") {
    flex-wrap: wrap;
  }
}

.column {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  width: 100%;
  margin: 0 5%;

  @include media(">medium") {
    flex-wrap: wrap;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;

    @include media(">small") {
      flex-direction: column;
    }
  }
}
