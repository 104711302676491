@import "../__vendors/normalize";
@import "../__vendors/include-media";
@import "fonts";
@import "colors";
@import "breakpoints";

* {
  box-sizing: border-box;
}

html,
body {
  background: rgb(246, 246, 246);
  display: flex;
  flex-direction: column;
}

.alert {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  background: rgba(242, 222, 222, 0.3);
  border: 1px solid rgba(235, 204, 209, 0.27);
  color: #a94442;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
}

.alert-link {
  color: #843534;
  font-weight: bold;
}
