@font-face {
  font-family: "CeraPro";
  src:
    url("../fonts/cerapro/Cera-Pro-Regular.woff2") format("woff2"),
    url("../fonts/cerapro/Cera-Pro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "CeraPro";
  src:
    url("../fonts/cerapro/Cera-Pro-Bold.woff2") format("woff2"),
    url("../fonts/cerapro/Cera-Pro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
