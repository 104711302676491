$colors-01-primary-04-system-grey-d-3: #c4c9d2;
$colors-01-primary-04-system-grey-2: #d1d5dc;
$colors-01-primary-04-system-grey-d-4: #cbd0d8;
$colors-01-primary-04-system-grey-l-4: #dee1e6;
$colors-01-primary-04-system-grey-l-5: #e5e7eb;
$colors-01-primary-04-system-grey-l-6: #f8f9fa;
$colors-01-primary-02-dark-night-black: #122020;
$colors-02-secondary-03-raspberry-pink: #e62e73;
$colors-01-primary-01-machtfit-green: #34b78f;
$colors-02-secondary-02-sunshine-yell: #fbd872;
$colors-02-secondary-01-aquafit-blue: #008ca0;
$colors-02-secondarya-01-aquafit-blue: #cce8ec;
$colors-02-secondary-01-aquafit-blu-2: #34a3b3;
$colors-02-secondary-01-aquafit-blu-3: #66bac6;
$colors-02-secondary-01-aquafit-blu-4: #98d1d9;
$colors-02-secondary-02-sunshine-ye-2: #fef7e3;
$colors-02-secondary-02-sunshine-ye-3: #fdefc7;
$colors-02-secondary-02-sunshine-ye-4: #fde8aa;
$colors-02-secondary-02-sunshine-ye-5: #fce08e;
$colors-02-secondary-03-raspberry-p-3: #f082ab;
$colors-02-secondary-03-raspberry-p-2: #eb588f;
$colors-02-secondary-03-raspberry-p-4: #f5acc7;
$colors-02-secondary-03-raspberry-p-5: #fad5e2;
$colors-01-primary-01-machtfit-gree-2: #37c49a;
$colors-01-primary-01-machtfit-gree-3: #2c9e7c;
$colors-01-primary-01-machtfit-gree-4: #22785e;
$colors-01-primary-02-dark-night-bl-2: #414d4d;
$colors-01-primary-02-dark-night-bl-3: #717979;
$colors-01-primary-02-dark-night-bl-4: #a0a6a6;
$colors-01-primary-02-dark-night-bl-5: #d0d2d2;
$zen-white: #ffffff;
$colors-01-primary-04-system-grey-dark: #bec4ce;
$colors-zen-wei-60: #eeeeee;
$colors-zen-wei-40: #e5e5e5;
$colors-graphite-light: #383838;
$colors-graphite: #212429;
$colors-zen-wei-80: #f6f6f6;
$colors-grau: #cfcfcf;
$colors-nacht-schwarz-90: #293636;
