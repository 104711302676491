.language-switcher__medium {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  margin-bottom: 5px;
  order: 1;
  width: 100%;

  @include media(">small") {
    margin-top: 46px;
    margin-bottom: 0;
  }

  input {
    border: none;
    background: none;
    text-transform: uppercase;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 14px;
    font-weight: normal;

    line-height: 1.14;
    letter-spacing: 0.5px;
    color: rgba(18, 32, 32, 0.6);
    margin: 0 20px;
    cursor: pointer;

    &.active {
      color: rgba(18, 32, 32, 0.9);
    }
  }

  .pipe {
    height: 30px;
    width: 1px;
    background-color: $colors-01-primary-04-system-grey-dark;
  }
}

.language-switcher__mini {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: none;
    background: none;
    text-transform: uppercase;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 14px;
    color: $colors-01-primary-02-dark-night-black;

    line-height: 20px;
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }

  .pipe {
    height: 25px;
    width: 1px;
    background-color: $colors-01-primary-02-dark-night-black;
  }
}
