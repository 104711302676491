.form-container {
  border-radius: 10px;
  box-shadow: 0 10px 25px 0 rgba(112, 144, 176, 0.2);
  background-color: $zen-white;
  display: flex;
  flex-direction: column;
  max-width: 459px;
  width: 100%;
  padding: 21px;

  @include media(">small") {
    min-width: 400px;
  }

  .errorlist {
    padding: 0;
    margin: 0;
    padding-left: 18px;
    margin-bottom: 15px;
  }

  .password-with-reveal,
  .form-group {
    position: relative;

    input {
      width: 100%;
    }

    .eye,
    .glyphicon {
      background-image: url("../images/login/eye.svg");
      width: 23px;
      height: 23px;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 8px !important;
      cursor: pointer;
      @include media(">small") {
        top: 14px !important;
      }
    }
    input[type="text"] + .eye,
    input[type="text"] + .glyphicon {
      background-image: url("../images/login/eye-open.svg");
    }
  }

  .help-text {
    label {
      display: none;
    }
    input {
      width: 100%;
    }

    &.plain {
      margin-top: -20px;
      p {
        font-size: 0.8rem;
        color: #a0a6a6;
        font-family: "CeraPro";
        font-feature-settings: "ss01" 1;
      }
    }
  }

  select {
    appearance: none;
  }

  input,
  select {
    height: 40px;
    border-radius: 6px;
    border: solid 1px $colors-01-primary-04-system-grey-dark;
    margin-bottom: 18px;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 12px;
    font-weight: normal;

    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $colors-01-primary-02-dark-night-bl-2;
    padding: 0 18px;
    width: 100%;

    @include media(">small") {
      height: 52px;
    }

    &:focus {
      border: solid 1px #0073dc;
      outline: none;
    }

    &[type="password"] {
      letter-spacing: 2.5px;
    }
    &::placeholder {
      letter-spacing: 0.5px;
    }
  }

  select:required:invalid {
    color: #757575;
  }

  option {
    color: #414d4d;
  }

  .field-error {
    color: $colors-02-secondary-03-raspberry-pink;
    border-color: $colors-02-secondary-03-raspberry-pink;

    &::placeholder {
      color: $colors-02-secondary-03-raspberry-pink;
    }
  }

  .companies {
    display: flex;
    margin: 0 0 15px;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    @include media("<small") {
      flex-wrap: wrap;
    }
  }

  .company {
    border-radius: 16px;
    border: solid 1px $colors-01-primary-04-system-grey-dark;
    background-color: $zen-white;
    max-width: 207px;
    padding: 5px;
    width: 47%;
    margin-bottom: 9px;
    max-width: 196px;
    padding: 5px;
    margin: 2px;
    height: 82px;
    cursor: pointer;

    a {
      display: flex;
      height: 100%;
      width: 100%;
    }
  }

  .company-logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    height: 100%;
    width: 100%;
  }

  label {
    display: none;
  }

  .checkbox {
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 12px;
    font-weight: normal;

    line-height: 1.33;
    letter-spacing: normal;
    color: $colors-01-primary-02-dark-night-bl-3;

    label {
      display: flex;
      align-items: center;
    }

    a {
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 12px;
      font-weight: normal;

      line-height: 1.33;
      letter-spacing: normal;
      color: $colors-01-primary-02-dark-night-bl-3;
    }

    input {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: solid 1px $colors-01-primary-04-system-grey-dark;
      margin: unset;
      margin-right: 20px;
    }
  }

  .horizontal-radio-select {
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 20px;

    li {
      list-style: none;
      margin-right: 20px;
    }

    input {
      border-color: rgba(18, 32, 32, 0.3);
      margin: 0;
      width: 22px;
      height: 22px;
      border: solid 1px $colors-01-primary-04-system-grey-dark;
    }

    label {
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 12px;
      font-weight: normal;

      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      color: rgba(18, 32, 32, 0.3);
    }
  }
}

.text-error,
.errorlist {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 12px;
  font-weight: normal;

  line-height: 1;
  letter-spacing: 0.5px;
  color: $colors-02-secondary-03-raspberry-pink;
}

.text-error {
  height: 29px;

  @include media(">small") {
    height: 69px;
  }
}

.button {
  width: 100%;
  height: 40px;
  border-radius: 26px;
  box-shadow: 0 2px 5px 0 rgba(18, 32, 32, 0.1);
  background-color: var(--subdomain-color);
  border: none;
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 16px;
  font-weight: bold;

  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: $zen-white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(">small") {
    height: 52px;
  }
}

.anchor {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 12px;
  font-weight: normal;

  line-height: 1.25;
  letter-spacing: normal;
  color: rgba(18, 32, 32, 0.8);
  text-decoration: none;
  display: block;
  @include media(">small") {
    font-size: 16px;
  }
}

.forgotten-password {
  text-align: right;
  margin-bottom: 8px;
}

.password-reset {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 14px;
  font-weight: normal;

  line-height: 1.29;
  letter-spacing: 0.5px;
  color: rgba(18, 32, 32, 0.6);
}

.register {
  text-align: center;
  margin-top: 18px;
  @include media(">small") {
    margin-top: 31px;
  }
}

.link {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 16px;
  font-weight: normal;

  line-height: 1.25;
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--subdomain-color);
  text-decoration: none;
  text-align: left;
}

.button:enabled {
  cursor: pointer;
}

.form-container .bahn-register.horizontal-radio-select label {
  color: #757575;
}
