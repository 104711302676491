.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px 0;

  color: rgb(113, 121, 121);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  line-height: 20px;

  span {
    margin: 0 30px;
  }
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgb(190, 196, 206);
  height: 2px;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.separator-full-width {
  position: relative;
  margin: 23px 0;
  width: calc(100% + 42px);
  left: -21px;
}
