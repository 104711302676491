.two-columns {
  .column {
    &:first-child {
      @include media("<=large") {
        display: none;
      }
    }

    @include media(">small") {
      width: 50%;
      margin: 0;
    }
  }

  .main-content {
    @include media(">large") {
      align-items: flex-end;
      position: absolute;
      left: 50%;
    }
  }
}
