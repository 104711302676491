@import "../../../node_modules/tippy.js/dist/tippy.css";
@import "../../../node_modules/tippy.js/dist/border.css";

.tippy-box {
  box-shadow: 0 5px 10px 0 rgba(112, 144, 176, 0.1);
  border: 1px solid $colors-01-primary-04-system-grey-dark;
  background: $zen-white;
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 12px;
  font-weight: normal;

  line-height: 1.5;
  letter-spacing: 0.6px;
  color: $colors-01-primary-02-dark-night-bl-3;

  .title {
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 12px;
    font-weight: bold;

    line-height: 1;
    letter-spacing: 0.5px;
    color: $colors-01-primary-02-dark-night-bl-4;
  }

  .tippy-arrow {
    color: $zen-white;
  }
}

dl.with-checkmarks {
  font-size: 16px;
  margin: 20px;
  width: 350px;
  dt {
    font-weight: bold;
    margin: 10px 0;
  }

  dd {
    margin-left: 5px;
  }

  dd::before {
    content: "✓";
    margin-right: 5px;
  }
}

.tooltip-arrow {
  position: absolute;
  top: 0px;
  transform: translate(0px, 140px);
  left: 0;
  width: 16px;
  height: 16px;

  &:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: #fff;
    transform-origin: center right;
  }

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    left: -8px;
    top: 0;
    border-right-color: #bec4ce;
  }
}

.tooltip-root {
  background: #fff;
  border: 1px solid #bec4ce;
  box-shadow: 0 5px 10px 0 rgb(112 144 176 / 10%);
  color: #717979;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: 1.5;
  border-radius: 4px;
}

.tooltip-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}
