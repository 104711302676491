.searchable-select {
  position: relative;

  input {
    transition: 0.1s ease-in-out;
    cursor: pointer;
  }

  ul {
    visibility: hidden;
    list-style: none;
    overflow: auto;
    max-height: 320px;
    transition: 0.1s ease-in-out;
    position: absolute;
    top: 52px;
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: $zen-white;
  }

  li {
    position: relative;
    cursor: pointer;
    transition: background-color 0.1s;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $colors-01-primary-02-dark-night-black;
    padding: 16px 11px;

    &:hover {
      color: $zen-white;
      background-color: var(--subdomain-color);
    }
  }
}
