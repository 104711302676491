.login-page {
  font-family: CeraPro;
  font-feature-settings: "ss01" 1;

  .button-primary {
    background-color: $colors-01-primary-01-machtfit-green;

    &:disabled {
      opacity: 0.6;
    }
  }

  .button-secondary {
    background-color: $colors-02-secondary-01-aquafit-blue;
    &:before {
      content: "";
      height: 19px;
      width: 18px;
      margin-right: 11px;
      background-image: url("../images/icon-login.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .side-action-panel {
    p,
    span {
      color: $colors-01-primary-02-dark-night-black;
      font-size: 20px;
      font-weight: normal;
      line-height: 28px;
      margin-bottom: 8px;
    }

    button,
    a {
      color: $colors-nacht-schwarz-90;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .token-sent {
    .message-container {
      display: flex;

      .email-sent-icon {
        align-self: center;
        height: 35px;
        width: 35px;
        margin-right: 10px;
        background-image: url("../images/icon-email-sent.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }

      .link-expired-icon {
        align-self: center;
        height: 35px;
        width: 35px;
        margin-right: 10px;
        background-image: url("../images/icon-link-expired.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }

      p {
        flex: 1;
        align-self: center;
      }
    }

    strong {
      white-space: nowrap;
    }

    button {
      flex: 0;
      display: inline;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .login-without-password-hint {
    visibility: hidden;
    position: relative;
    z-index: 100;
    width: 220px;
    height: 107px;
    margin-bottom: -107px; // Remove space reserved at the original position
    background-color: $zen-white;
    border-radius: 10px;
    border: 1px solid $colors-01-primary-02-dark-night-bl-5;
    top: -80px;
    left: -220px;
    box-shadow: 0 10px 25px 0 rgba(112, 144, 176, 0.2);
    padding: 20px;
    font-size: 14px;
    color: $colors-01-primary-02-dark-night-black;

    h4 {
      margin: 0;
      font-size: 16px;
      height: 18px;
    }

    p {
      line-height: 20px;
      margin-top: 7px;
    }

    &:after,
    &:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(248, 255, 38, 0);
      border-left-color: $zen-white;
      border-width: 10px;
      margin-top: -10px;
    }

    &:before {
      border-color: rgba(112, 144, 176, 0);
      border-left-color: $colors-01-primary-02-dark-night-bl-5;
      border-width: 11px;
      margin-top: -11px;
    }
  }

  @media not screen, (max-width: 1000px) {
    .login-without-password-hint {
      display: none;
    }
  }

  .text-error {
    height: 2rem;
    visibility: hidden;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 90%;
  }

  .forgot-password {
    font-size: 16px;
    line-height: 20px;
    color: $colors-01-primary-02-dark-night-bl-2;
    text-align: center;
  }
}
