@mixin slide-image($color, $image) {
  background-image: linear-gradient(to bottom, $color, $color), url($image);
}

.slider-column {
  position: fixed;

  footer {
    position: fixed;
    bottom: 54px;
    z-index: 20;
    left: 6.5%;
    .copyright {
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 16px;
      font-weight: normal;

      line-height: 1;
      letter-spacing: normal;
      color: $zen-white;
    }
  }

  .slider {
    width: 100%;
    height: 100vh;
  }

  .slide {
    width: 100%;
    height: 100%;

    .hero {
      height: 100%;
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: auto 100%;
    }

    .willkommen {
      @include slide-image(rgba(52, 183, 143, 0.9), "../images/login/willkommen.jpg");
    }

    .angebote {
      @include slide-image(rgba(0, 140, 160, 0.9), "../images/login/angebote.jpg");
    }

    .inhalte {
      @include slide-image(rgba(251, 216, 114, 0.9), "../images/login/inhalte.jpg");
    }

    .willkommen.subdomain {
      @include slide-image(
        rgba(
          var(--subdomain-color-red),
          var(--subdomain-color-green),
          var(--subdomain-color-blue),
          0.9
        ),
        "../images/login/willkommen.jpg"
      );
    }

    .angebote.subdomain {
      @include slide-image(
        rgba(
          var(--subdomain-color-red),
          var(--subdomain-color-green),
          var(--subdomain-color-blue),
          0.9
        ),
        "../images/login/angebote.jpg"
      );
    }

    .inhalte.subdomain {
      @include slide-image(
        rgba(
          var(--subdomain-color-red),
          var(--subdomain-color-green),
          var(--subdomain-color-blue),
          0.9
        ),
        "../images/login/inhalte.jpg"
      );
    }

    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      margin-left: 13%;
      margin-right: 26%;
    }

    .cta-title {
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 48px;
      font-weight: bold;

      line-height: 1.07;
      letter-spacing: normal;
      color: $zen-white;
      margin-bottom: 20px;
    }

    .description {
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 38px;
      font-weight: normal;

      line-height: 1.08;
      letter-spacing: normal;
      color: $zen-white;
    }
  }
}
