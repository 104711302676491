.spinner {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;

  img {
    margin-bottom: 20px;
    width: 150px;
  }

  p {
    display: block;
    text-align: center;
    font-size: 24px;
    opacity: 0.2;
    padding-left: 0;
  }
}
