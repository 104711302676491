.pwd-validation__header,
.form-group__char-left {
  display: none;
}

.pwd-validation__list-title {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 14px;
  font-weight: normal;

  line-height: 1.25;
  letter-spacing: normal;
  color: rgba(18, 32, 32, 0.6);
  margin: 0;
}

.pwd-validation__list {
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  font-size: 12px;
  font-weight: normal;

  line-height: 1.5;
  letter-spacing: 0.6px;
  color: $colors-01-primary-02-dark-night-bl-4;

  li:before {
    content: "";
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    left: -20px;
    top: 7px;
    position: absolute;
    background: $colors-01-primary-02-dark-night-bl-4;
  }
}

.pwd-validation {
  ul {
    list-style: none;
  }

  li {
    position: relative;
    margin-bottom: 5px;
  }

  &--valid,
  &--semivalid,
  &--invalid {
    li {
      color: $colors-02-secondary-03-raspberry-pink;
      &:before {
        background-color: transparent;
        height: 16px;
        width: 16px;
        border-radius: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        background-image: url("../images/invalid.svg");
        left: -29px;
        top: 2px;
      }

      &.valid {
        color: rgba(52, 183, 143, 0.9);
        &:before {
          background-image: url("../images/valid.svg");
        }
      }
      &.hint-only {
        color: #a0a6a6;
        &:before {
          background: #a0a6a6;
          border-radius: 50%;
          height: 5px;
          left: -20px;
          top: 7px;
          width: 5px;
        }
      }
    }
    .psw-validation__body div:nth-child(2) {
      li {
        color: $colors-01-primary-02-dark-night-bl-4;

        &:before {
          content: "";
          display: block;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          left: -20px;
          top: 7px;
          position: absolute;
          background: #a0a6a6;
          background-image: none;
        }
        &.valid {
          color: rgba(52, 183, 143, 0.9);

          &:before {
            background-color: transparent;
            height: 16px;
            width: 16px;
            border-radius: 0;
            background-repeat: no-repeat;
            background-size: 100%;
            left: -29px;
            top: 2px;
            background-image: url("../images/valid.svg");
          }
        }
      }
    }
  }
}

.psw-validation__body div:first-child .pwd-validation__list-title {
  display: none;
}
